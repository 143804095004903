import React, { useRef } from "react"
import * as D3 from "d3"
import SEO from "../../../components/seo"
import useContainerDimensions from "../../../components/useContainerDimensions"
import barChart from "../../../components/barChart"
import styles from "./water-footprint.module.css"
import "../../general.css"
import SustainabilityData from "../../../data/higg-sustainability-index.json"
import { PlantsGreenColor, AnimalsRedColor } from "../../../defines.js"
import {
    chartTitleTextStyle,
    vizBackgroundStyle
} from "../../../defines";

const Title = "Higg Materials Sustainability Index";

const Sustainability = ({ mobile }) => {
    let GraphAspectRatio = 4 / 3;
    if (mobile) {
        GraphAspectRatio = 3 / 4;
    }
    const graphDivRef = useRef();
    const { width } = useContainerDimensions(graphDivRef);
    const svgWidth = Math.max((width - 10), 0);
    const svgHeight = Math.max((width - 10) / GraphAspectRatio, 0);
    const margin = {
        left: mobile ? 81 : 0,
        top: mobile ? 25 : 20,
        right: mobile ? 20 : 35,
        bottom: mobile ? 5 : 35
    };
    const renderProducts = () => {
        if (typeof document === "undefined") {
            return;
        }
        const materialsSvg = D3.select(".materials");
        let dataObj = {};
        let colors = {};
        SustainabilityData.plant.forEach(kvp => {
            dataObj[kvp.name] = kvp.value;
            colors[kvp.name] = PlantsGreenColor;
        });
        SustainabilityData.animal.forEach(kvp => {
            dataObj[kvp.name] = kvp.value;
            colors[kvp.name] = AnimalsRedColor;
        });
        let barChartG = materialsSvg.select(".barChartG");
        if (barChartG.empty()) {
            barChartG = materialsSvg.append("g");
            barChartG.attr("class", "barChartG");
        }
        barChartG.call(barChart, {
            ascending: !mobile,
            data: dataObj,
            width: svgWidth,
            height: svgHeight,
            margin: margin,
            fillColor: colors,
            tooltipClass: styles.tooltip,
            valuesAxisFormat: ".2s",
            splitAxisOnSpace: !mobile,
            isHorizontal: mobile,
            mobile
        });
    };

    renderProducts();
    return (
        <>
            <SEO title={Title} />
            <div className="contentDiv">
                <h2 className="chartTitle" style={chartTitleTextStyle(mobile)}>{Title}</h2>
                <div className="vizBackground" style={vizBackgroundStyle(mobile)}>
                    <div className={styles.tooltip} style={{ opacity: 0 }} />
                    <div className={`citation ${styles.textAlignCenter}`} style={{ paddingTop: 0, width: mobile ? "95%" : "75%", margin: "auto" }}>
                        The Higg Index is an apparel and footwear industry self-assessment standard for assessing environmental and social sustainability throughout the supply chain. The higher the number, the higher the impact.
                </div>
                    <div className="FlexGrow" ref={graphDivRef} style={{ textAlign: "center", marginTop: 20 }}>
                        <svg className="materials" width={`${svgWidth}px`} height={`${svgHeight}px`} />
                    </div>
                    <div className="citation" style={{ marginTop: "20px" }}>
                        Data source: <a href="https://apparelinsider.com/higg-materials-sustainability-index-updated/">
                            Apparel Insider</a><br />
                    </div>
                </div>
            </div>
        </>
    );
}

export default Sustainability;
